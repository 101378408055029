import store from "@/store";

function isNotAuthenticated(to, from, next) {
    if (!store.getters['auth/isAuthenticated']) {
        next()
        return
    }
    next('/')
}

export default [
    {
        path: "/",
        name: 'main.dashboard',
        component: () => import('@/components/main/dashboard'),
        meta: {requiresAuth: true, requiresPerm: false,layout: 'central', title: 'Kub Partners'}
    },
    {
        path: "/analysis",
        name: 'monitoring.analysis',
        component: () => import('@/components/analysis/prices/analysis'),
        meta: {requiresAuth: true, requiresPerm: 'analysis_price',layout: 'central', title: 'Аналітика цін'}
    },
    {
        path: "/sites",
        name: 'monitoring.sites',
        component: () => import('@/components/report/sites/sites'),
        meta: {requiresAuth: true, requiresPerm: 'report_sites',layout: 'central', title: 'Звіт по сайтам'}
    },
    {
        path: "/sales",
        name: 'monitoring.sales',
        component: () => import('@/components/analysis/sales/sales'),
        meta: {requiresAuth: true, requiresPerm: 'analysis_sales', layout: 'central', title: 'Аналітика продажів'}
    },
    {
        path: "/content",
        name: 'content.product',
        component: () => import('@/components/content/product'),
        meta: {requiresAuth: true, requiresPerm: 'content_site',layout: 'central', title: 'Контент'}
    },
    {
        path: "/ads-site",
        name: 'ads.site',
        component: () => import('@/components/ads/site/main'),
        meta: {requiresAuth: true, requiresPerm: 'ads_site',layout: 'central', title: 'Реклама на сайті'}
    },
    {
        path: "/ads-site/banner",
        name: 'ads.site.order.banner',
        component: () => import('@/components/ads/site/order_ads_banner'),
        meta: {requiresAuth: true, requiresPerm: 'ads_site',layout: 'central', title: 'Замовлення реклами банера на сайті'}
    },
    {
        path: "/ads-site/product",
        name: 'ads.site.order.product',
        component: () => import('@/components/ads/site/order_ads_product'),
        meta: {requiresAuth: true, requiresPerm: 'ads_site',layout: 'central', title: 'Замовлення реклами банера на сайті'}
    },
    {
        path: "/ads-account",
        name: 'ads.account',
        component: () => import('@/components/ads/account/main'),
        meta: {requiresAuth: true, requiresPerm: 'ads_account',layout: 'central', title: 'Рекламний кабінет'}
    },
    {
        path: "/ads-account/ads",
        name: 'ads.account.google_ads',
        component: () => import('@/components/ads/account/google_ads'),
        meta: {requiresAuth: true, requiresPerm: 'ads_account',layout: 'central', title: 'Google Ads'}
    },
    {
        path: "/ads-account/shopping",
        name: 'ads.account.google_shopping',
        component: () => import('@/components/ads/account/google_shopping'),
        meta: {requiresAuth: true, requiresPerm: 'ads_account',layout: 'central', title: 'Google Shopping'}
    },
    {
        path: "/login",
        name: 'central.login',
        component: () => import('../components/auth/LoginCentral'),
        meta: {requiresAuth: false, requiresPerm: false,layout: 'central', title: 'Kub Partners'},
        beforeEnter: isNotAuthenticated
    },
    {
        path: '/404',
        name: '404',
        component: () => import('../components/central/NotFound'),
    },
    {
        path: '/access',
        name: '403',
        component: () => import('../components/central/AccessForbidden'),
    },
    {
        path: "/:catchAll(.*)",
        redirect: '/404',
    },
]

