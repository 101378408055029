<template>
  <footer class="d-flex justify-center mb-3" v-if="isAuthenticated">
    {{ new Date().getFullYear() }} — <strong>KUB</strong>
  </footer>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "FooterCentral",
  props: {
    msg: String
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters('auth', {
      isAuthenticated: 'isAuthenticated',
      authUser: 'authUser',
      isTypeUser: 'isTypeUser',
      isVerification: 'isVerification'
    }),
  }

}
</script>

<style scoped>

</style>
