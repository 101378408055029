import {createRouter, createWebHistory} from "vue-router"

require("@/store")
import store from "@/store";

import routes from './routes'
/*
let routes = [].concat(
    central,
    account,
    master,
    customer
)*/

const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

router.beforeResolve(async (to) => {
    const isAuthenticated = await store.getters['auth/isAuthenticated'];
    const authUser = await store.getters['auth/authUser'];

    //check in Authentication User for route
    if (to.meta.requiresAuth && !isAuthenticated) {
        return {
            path: '/login',
            query: {redirect: to.fullPath},
        }
    }

    //check in Verification for route
    if (to.meta.requiresPerm && authUser.permissions && isAuthenticated && !authUser.permissions.includes(to.meta.requiresPerm)) {
        store.commit('auth/currentPage', {
            name:to.name,
        });
        return {
            path: '/access',
        }
    }
    document.title = to.meta.title || 'App';
});

export default router
