import router from "@/router";
import store from "@/store";

const success = (response) => {

    if (response.data.errors) {
        let errors = response.data.msg
        console.log('(GLOBAL: success errors) response', errors)
    } else if (response.data.msg ?? false) {
        let message = response.data.msg
        console.log('(GLOBAL: success success_ response', message)
    }

    return response
}

const error = (error) => {
        if (error.response.status === 303) {
            console.log('GLOBAL: error 303 response', error.response)
        }
        if (error.response.status === 401) {
            store.commit('auth/isAuthenticated', false)
            store.commit('auth/authUser', {})
            store.commit('auth/userPermissions', [])
            router.push({name: 'central.login'})
            console.log('Unauthorized: error 401 response', error.response)
        }

        if (error.response.status === 403) {
            router.push({name: '403'})
        }

        if (error.response.status === 404) {
            router.push({name: '404'})
        }

        if (error.response.status === 419) {
            store.commit('auth/isAuthenticated', false)
            store.commit('auth/authUser', {})
            store.commit('auth/userPermissions', [])
            router.push({name: 'central.login'})
            console.log('CSRF token mismatch: error 419 response', error.response)
        }

        if (error.response.status === 422) {
            console.log('Unprocessable Content: error 422 response', error.response)
        }

        if (error.response.status === 500) {
            console.log('GLOBAL: error 500 response', error.response)
        }

    return error
}

export {success, error}
