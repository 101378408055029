<template>
  <v-app-bar class="px-5"
             color="white"
             flat
             density="compact" v-if="isAuthenticated">
    <v-container class="d-flex align-center justify-sm-space-between header-container">
      <router-link :to="{name:'main.dashboard'}" class="nav-logo-wrap">
      <img class="nav-logo mr-8" src="/logo.png">
      </router-link>

      <div class="top-nav-items">
        <router-link :to="{name:'monitoring.analysis'}" exact :class="{ 'active': $route.name === 'monitoring.analysis' }">Аналітика цін</router-link>
        <router-link :to="{name:'monitoring.sites'}" exact :class="{ 'active': $route.name === 'monitoring.sites' }">Звіт по сайтам</router-link>
        <router-link :to="{name:'monitoring.sales'}" exact :class="{ 'active': $route.name === 'monitoring.sales' }">Аналітика продажів</router-link>
        <router-link :to="{name:'content.product'}" exact :class="{ 'active': $route.name === 'content.product' }">Контент</router-link>
        <router-link :to="{name:'ads.site'}" exact :class="{ 'active': $route.name === 'ads.site' }">Реклама на сайті</router-link>
        <router-link :to="{name:'ads.account'}" exact :class="{ 'active': $route.name === 'ads.account' }">Рекламний кабінет</router-link>
      </div>

      <a v-if="authUser.tg_group" :href="authUser.tg_group" target="_blank" class="ml-auto">
       <span class="icon-telegram"></span>
        Telegram
      </a>

      <span class="user-name-wrap">
        <v-avatar color="grey-lighten-3"><v-icon color="grey-lighten-1" icon="mdi-account-circle"></v-icon></v-avatar>
        <span class="ms-3 user-name">{{ authUser.name }}</span>
        <span @click="logout" class="mdi mdi-logout ms-1 fs-18"></span>
      </span>

    </v-container>
  </v-app-bar>
</template>

<script>
import {mapGetters} from "vuex";
//import SwitchLocale from "@/components/layouts/SwitchLocale";
import axios from "axios";
import store from "@/store";
export default {
  name: "UppMenuCentral",
  //components: {SwitchLocale},
  computed: {
    ...mapGetters('auth', {
      isAuthenticated: 'isAuthenticated',
      authUser: 'authUser',
      isTypeUser: 'isTypeUser',
      isVerification: 'isVerification'
    }),
  },
  data() {
    return {
      status_location:false
    };
  },
  mounted() {

  },
  methods: {
    async logout() {
      axios.post('/api/auth/logout').then(r => {
        if (r.status) {
          store.commit('auth/isAuthenticated', false)
          store.commit('auth/authUser', {})
          localStorage.removeItem('X-XSRF-TOKEN')
          this.$router.push({name: 'central.login'})
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
